import type { Ref } from "@vue/composition-api"
import { ref } from "@vue/composition-api"
import { createSidetab } from "@typeform/embed"
import type { SidetabOptions } from "@typeform/embed/types/factories/create-sidetab/sidetab-options"
import type { Sidetab } from "@typeform/embed/types/factories/create-sidetab/create-sidetab"
import "@typeform/embed/build/css/sidetab.css"

type HiddenFields = Record<string, string>

function getFormId() {
  if (process.env.VUE_APP_SURVEY_FORM_ID === undefined) {
    throw new Error("process.env.VUE_APP_SURVEY_FORM_ID is undefined")
  }
  return process.env.VUE_APP_SURVEY_FORM_ID
}

export default function useSurvey(
  fields: Ref<HiddenFields>,
  options: SidetabOptions = {}
) {
  const widget = ref<Sidetab>()

  function mount() {
    widget.value = createSidetab(getFormId(), {
      ...options,
      hidden: fields.value
    })
    widget.value.open()
  }
  function unmount() {
    if (widget.value !== undefined) {
      widget.value.unmount()
      widget.value = undefined
    }
  }

  return {
    mount,
    unmount
  }
}
