
























































































































































































// @ts-nocheck
import Vue from "vue"
import { mapGetters, mapActions } from "vuex"
import _ from "lodash"

import User from "@shared/User"
import Team from "@shared/Team"

import UserMedia from "@/components/GroupTeams/Common/User/UserMedia.vue"
import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import isGameOver from "@shared/helpers/isGameOver"
import { updateGameWith } from "@/services/game.service"
import { db } from "@/firebase"

import { isTipJarEnabled } from "@/modules/tipjar/util"

import { GameType } from "@/entities/GameType"
import Mission from "@shared/enums/Mission"
import Mode from "@shared/enums/Mode"
import TipJar from "@shared/enums/TipJar"

import { RtbButton } from "@/components/ui"
import { KEY_U } from "keycode-js"

import useRoomSurvey from "@/use/useRoomSurvey"

const N_OF_MAX_USERS_WHEN_SCREEN_SHARE = 15
const DEFAULT_REFERRAL_LINK = "https://try.weve.co/app/signup"

export default Vue.extend({
  name: "MeetingMode",
  components: {
    DownloadPdf: () =>
      import(
        "@/components/GroupTeams/Common/Games/GameCardParts/DownloadPdf.vue"
      ),
    UserMedia,
    AppUserCard,
    MeetingWithBreakouts: () =>
      import(
        "@/components/GroupTeams/Common/GameModes/Meeting/MeetingWithBreakouts.vue"
      ),
    MediaBannerWithHubSpotForm: () =>
      import("@/components/MediaBannerWithHubSpotForm"),
    MeetingSmartGrid: () =>
      import("@/components/GroupTeams/Common/GameModes/MeetingSmartGrid"),
    WhiteElephantGrid: () =>
      import("@/components/GroupTeams/Common/GameModes/WhiteElephantGrid"),
    MeetingUserCard: () =>
      import(
        "@/components/GroupTeams/Common/Player/MeetingUserCard/MeetingUserCard"
      ),
    SlidesWrapper: () =>
      import("@/components/GroupTeams/Common/Games/Slide/SlideWrapper.vue"),
    ScreenShare: () => import("@/components/ScreenCapture/ScreenShare"),
    VideoMission: () =>
      import("@/components/GroupTeams/Common/Games/VideoMission"),
    RtbButton
  },
  setup() {
    useRoomSurvey()
  },
  data() {
    return {
      hasScrollbar: false,
      scrollAmount: 0,
      referralLinkLock: true
    }
  },
  mounted() {
    if (this.isHost) {
      window.addEventListener("keyup", this.onKeyUp)
    }

    setTimeout(() => {
      this.referralLinkLock = false
    }, 10000)

    this.checkScrollbar()
  },
  beforeDestroy() {
    if (this.isHost) {
      window.removeEventListener("keyup", this.onKeyUp)
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "isModerator", "user", "clientID"]),
    ...mapGetters({ hosts: "gameHosts", host: "gameHost" }),
    ...mapGetters([
      "onlineUsersArray",
      "actualGame",
      "actualGameID",
      "isDIYGame",
      "orgID",
      "getSelectedMeetingUsers",
      "moderatorID",
      "getMeetingModeActiveUser",
      "gameStatus"
    ]),
    ...mapGetters("twilio", ["screenshareVideoTrack", "screenshareAudioTrack"]),
    ...mapGetters("ScreenCapture", ["isSharingScreen"]),
    ...mapGetters("Games", ["isMeetingMediaAvailable"]),
    teams() {
      return Team.normalize(this.$store.getters.chats || {})
    },
    hasBreakouts() {
      return this.teams.some(({ show, breakout }) => breakout && show)
    },
    nOfUsers() {
      return parseInt(this.allUsers?.length) || 0
    },
    isBottomUserPanelVisible() {
      if (!this.isMeetingMediaAvailable && !this.isGreenRoom) return false
      if (this.nOfUsers < 1) return false
      if (!this.isSharing) return true
      return true
    },
    mediaDisabled() {
      return this.nOfUsers > N_OF_MAX_USERS_WHEN_SCREEN_SHARE
    },
    hasMediaBanner() {
      if (this.isWhiteElephant) return false

      return this.actualGame?.showAd
    },
    selectedUserID() {
      return typeof this.getMeetingModeActiveUser === "string"
        ? this.getMeetingModeActiveUser
        : null
    },
    sharingScreenOn() {
      return !!this.screenshareVideoTrack?.mediaStreamTrack
    },
    sharingAudioOn() {
      return !!this.screenshareAudioTrack?.mediaStreamTrack
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    isSlides() {
      return this.currentMission?.behavior === Mission.Slides
    },
    isWhiteElephant() {
      return this.currentMission?.behavior === Mission.WhiteElephant
    },
    isTheater() {
      return this.$store.getters.getCurrentMode === Mode.Theater
    },
    isMeeting() {
      return this.$store.getters.getCurrentMode === Mode.Meeting
    },
    isHostHidden() {
      return this.actualGame?.isHostHidden
    },
    isPlayerAdvance() {
      return this.actualGame?.isPlayerAdvance
    },
    isMuteAll() {
      return this.actualGame?.isGreenMeetingMuted
    },
    gameType() {
      return this.actualGame?.gameType
    },
    screenshareByUser() {
      const id = this.gameStatus?.shareRef?.screenShareBy
      return this.getAllPlayers.find(item => item.id === id)
    },
    presenterSeat() {
      if (!this.isSharing) return null
      const selectedUserId = this.selectedUserID
      return (
        this.onlineUsersArray.find(user => user?.id === selectedUserId) ??
        this.screenshareByUser
      )
    },
    // TODO - refactor this
    users() {
      const selectedUsersMap = this.getSelectedMeetingUsers || {}
      const presenter = this.presenterSeat

      return this.getAllPlayers.reduce(
        (acc, user) => {
          if (!presenter && selectedUsersMap[user.id]) {
            acc.selected.push(user)
            return acc
          }
          acc.all.push(user)
          return acc
        },
        {
          all: [],
          selected: []
        }
      )
    },
    selectedUsers() {
      return this.users.selected
    },
    allUsers() {
      return this.users.all
    },
    allUsersSortedByRaisehand() {
      // TODO - we need no chain here
      return _.chain(this.users.all).sortBy("raisehand.timestamp").value()
    },
    isGreenRoom() {
      return this.gameType === GameType.GreenRoom
    },
    getAllPlayers() {
      const { moderatorID, hosts: maybeHosts, isHostHidden } = this

      const hosts = Array.isArray(maybeHosts) ? maybeHosts : []

      const isTipJarActiveInGame = () =>
        [TipJar.AUTO, TipJar.ON].includes(this.actualGame.showTipJar)

      const hostsToAdd = hosts.filter(host => {
        const isHostInGame = User.isPlaying(host) || User.isSelectingTeam(host)

        const isTipJarEnabledOnHost = () =>
          isTipJarEnabled(
            this.$store.getters["auth/client"],
            this.actualGame,
            host
          )

        const isTipJarVisible = () =>
          isTipJarActiveInGame() && isTipJarEnabledOnHost()

        return (isHostInGame && !isHostHidden) || isTipJarVisible()
      })

      const pinnedUserID = this.gameStatus?.pinedUserID

      const isUserAlive = this.$store.getters.isUserAlive

      const array = _.chain(this.onlineUsersArray)
        .filter(
          user =>
            !User.isSpecialPurposeUser(user) &&
            User.isPlayer(user) &&
            User.isPlaying(user) &&
            isUserAlive(user) &&
            user.id !== moderatorID
        )
        .thru(users => users.concat(hostsToAdd))
        .sortBy("teamID")
        .sortBy(user => !User.isHost(user))
        .sortBy(user => user.id !== pinnedUserID)
        .value()

      return array
    },
    selectedUser() {
      return (
        (this.isSharing && this.presenterSeat) ||
        ((this.isSlides || this.isTheater || this.isSharing) &&
          this.selectedUsers.length === 1 &&
          this.selectedUsers[0])
      )
    },
    isSharing() {
      return this.sharingScreenOn || this.isSharingScreen || this.sharingAudioOn
    },
    presenterCentered() {
      return this.gameStatus?.presenterCentered
    },
    numberOfUsers() {
      return this.allUsersSortedByRaisehand.length
    },
    referralLink() {
      return (
        !this.isWhiteElephant &&
        isGameOver(this.currentMission) &&
        this.host?.showReferralLinkIframe &&
        !this.referralLinkLock &&
        (this.host?.referralLink || DEFAULT_REFERRAL_LINK)
      )
    }
  },
  watch: {
    // TODO - something not cool happening here
    isMuteAll() {
      this.muteAll()
    },
    numberOfUsers() {
      this.checkScrollbar()
    }
  },
  methods: {
    ...mapActions([
      "updateUser",
      "updateUsersInBulk",
      "updateMeetingModeActiveUser",
      "updateSelectedMeetingUser"
    ]),
    ...mapActions("Games", ["updateGame"]),
    async updateUserSelection(userID) {
      if (
        this.screenshareByUser &&
        (this.isHost || this.user.id === this.screenshareByUser.id)
      ) {
        this.updateMeetingModeActiveUser(
          this.screenshareByUser.id !== userID && userID
        )
        return
      }

      if (this.isHost) {
        if (this.isMuteAll) {
          const isMuted = !!this.getSelectedMeetingUsers?.[userID]
          await this.toggleMutedUserState(userID, isMuted)
        }

        this.updateSelectedMeetingUser({ userID })
      }
    },
    playerAdvance() {
      updateGameWith(this.actualGameID, this.orgID, {
        isPlayerAdvance: !this.isPlayerAdvance
      })
    },
    toggleMuteAll() {
      updateGameWith(this.actualGameID, this.orgID, {
        isGreenMeetingMuted: !this.isMuteAll
      })
    },
    async muteAll() {
      const array = this.allUsers.map(user => ({
        userID: user.id,
        collection: [{ key: "muted", value: this.isMuteAll }]
      }))
      await this.updateUsersInBulk({ array })
    },
    async toggleMutedUserState(userID, muted) {
      await this.updateUser({
        userID,
        obj: { muted }
      })
    },
    toggleMeetingMode() {
      updateGameWith(this.actualGameID, this.orgID, {
        gameType: this.isGreenRoom ? GameType.Standard : GameType.GreenRoom
      })
    },
    togglePesenterPosition() {
      db.auxiliary()
        .ref(
          `org/${this.orgID}/game/${this.actualGameID}/gameStatus/presenterCentered`
        )
        .set(!this.presenterCentered)
    },
    onKeyUp(e) {
      if (
        ["text", "textarea", "search", "number"].includes(e?.target?.type) &&
        ![null, undefined, ""].includes(e?.target?.value)
      )
        return

      if (e.keyCode === KEY_U && e.shiftKey) {
        this.togglePesenterPosition()
      }
    },
    checkScrollbar() {
      const scrollbar = this.$refs.usersRow
      this.hasScrollbar =
        scrollbar && scrollbar.scrollWidth > scrollbar.clientWidth
    },
    scrollLeft() {
      this.$refs.usersRow.scrollTo({
        top: 0,
        left: Math.min((this.scrollAmount -= 200), 0),
        behavior: "smooth"
      })
    },
    scrollRight() {
      this.$refs.usersRow.scrollTo({
        top: 0,
        left: Math.max(
          (this.scrollAmount += 200),
          this.$refs.usersRow.clientWidth
        ),
        behavior: "smooth"
      })
    },
    openReferralLinkInNewWindow() {
      window.open(this.referralLink)
    }
  }
})
