var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasBreakouts
    ? _c("MeetingWithBreakouts")
    : _c(
        "v-layout",
        {
          ref:
            !_vm.isGreenRoom &&
            !(_vm.isTheater || _vm.isSharing) &&
            "meetingGrid",
          staticClass: "meeting-container",
          attrs: { row: "", "justify-center": "", "fill-height": "" },
        },
        [
          _c(
            "transition",
            {
              attrs: {
                name: "meeting-container__referral-iframe-transition",
                mode: "out-in",
              },
            },
            [
              _vm.hasMediaBanner
                ? _c("MediaBannerWithHubSpotForm", {
                    staticClass: "meeting-container__referral-iframe",
                    attrs: { vertical: "" },
                  })
                : !!_vm.referralLink
                ? _c(
                    "div",
                    { staticClass: "meeting-container__referral-iframe" },
                    [
                      _c("iframe", {
                        staticClass: "referral-link-embed",
                        attrs: { src: _vm.referralLink, width: "100%" },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "referral-link-btn absolute bottom-0 right-0 mb-2 mr-2",
                          attrs: { color: "white", float: "" },
                          on: { click: _vm.openReferralLinkInNewWindow },
                        },
                        [
                          _c("svg-icon", {
                            attrs: { size: "large", name: "share" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.isGreenRoom || _vm.isMeetingMediaAvailable
            ? _c(
                "v-layout",
                { staticClass: "green-room-meeting", attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      ref: "meetingGrid",
                      staticClass: "selected-meeting-users",
                      attrs: { layout: "", column: "", grow: "" },
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _vm.isSharing && _vm.isHost
                            ? _c(
                                "RtbButton",
                                {
                                  staticClass: "absolute top-0 right-0 z-20",
                                  attrs: { variant: "icon" },
                                  on: { click: _vm.togglePesenterPosition },
                                },
                                [
                                  _c("SvgIcon", {
                                    attrs: {
                                      name: _vm.presenterCentered
                                        ? "north-east"
                                        : "south-west",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isMeetingMediaAvailable
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "meeting-container__meeting-addon",
                                },
                                [
                                  _vm.isSharing
                                    ? _c("ScreenShare", {
                                        staticStyle: { overflow: "hidden" },
                                      })
                                    : _vm.isSlides
                                    ? _c("SlidesWrapper", {
                                        attrs: { mission: _vm.currentMission },
                                      })
                                    : _vm.isTheater
                                    ? _c("VideoMission", {
                                        attrs: { mission: _vm.currentMission },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          !!_vm.selectedUser
                            ? _c("AppUserCard", {
                                key: _vm.selectedUser.id,
                                staticClass: "meeting-presenter",
                                class: {
                                  "meeting-presenter--top-right":
                                    !_vm.presenterCentered,
                                  "meeting-presenter--centered":
                                    _vm.presenterCentered,
                                },
                                attrs: { user: _vm.selectedUser },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateUserSelection(
                                      _vm.selectedUser.id
                                    )
                                  },
                                },
                              })
                            : _vm.selectedUsers.length > 0
                            ? _c("MeetingSmartGrid", {
                                staticClass:
                                  "meeting-container__smart-grid-overlay",
                                attrs: {
                                  pinUI: "",
                                  maxLength: _vm.isMeetingMediaAvailable
                                    ? 200
                                    : 500,
                                  minLength: 160,
                                  users: _vm.selectedUsers,
                                },
                                on: { select: _vm.updateUserSelection },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "users-row" }, [
                    _vm.isHost && _vm.hasScrollbar
                      ? _c(
                          "div",
                          { staticClass: "users-row__controls" },
                          [
                            _c(
                              "RtbButton",
                              {
                                attrs: {
                                  variant: "icon",
                                  size: "lg",
                                  circle: "",
                                  title: "Left",
                                },
                                on: { click: _vm.scrollLeft },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { name: "chevron-left-regular" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "RtbButton",
                              {
                                attrs: {
                                  variant: "icon",
                                  size: "lg",
                                  circle: "",
                                  title: "right",
                                },
                                on: { click: _vm.scrollRight },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { name: "chevron-right-regular" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isBottomUserPanelVisible
                      ? _c(
                          "div",
                          { ref: "usersRow", staticClass: "all-meeting-users" },
                          [
                            _c(
                              "div",
                              { staticClass: "all-meeting-users__scroll" },
                              _vm._l(
                                _vm.allUsersSortedByRaisehand,
                                function (player, j) {
                                  return _c(
                                    "div",
                                    {
                                      key:
                                        "meeting-player-" + player.id + "-" + j,
                                      staticClass:
                                        "all-meeting-users__scroll-item",
                                    },
                                    [
                                      _c("MeetingUserCard", {
                                        attrs: {
                                          user: player,
                                          mediaDisabled: _vm.mediaDisabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateUserSelection(
                                              player.id
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm.isWhiteElephant
            ? _c("WhiteElephantGrid")
            : _c("MeetingSmartGrid", {
                class: { "mb-5": _vm.isSlides },
                attrs: { pinUI: "", users: _vm.getAllPlayers },
              }),
          _vm.isSlides && !_vm.isTheater
            ? _c("DownloadPdf", {
                staticClass: "mx-auto my-2 absolute bottom-0",
              })
            : _vm._e(),
          _vm.isHost && !_vm.isModerator
            ? _c(
                "div",
                { staticClass: "meeting-controls" },
                [
                  _vm.isSlides
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            fab: "",
                                            small: "",
                                            "input-value": _vm.isPlayerAdvance,
                                            "active-class": "grey darken-1",
                                            active: _vm.isPlayerAdvance,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.playerAdvance()
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.isPlayerAdvance
                                                  ? "compare_arrows"
                                                  : "change_history"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2432372658
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isPlayerAdvance
                                    ? "Only Host Advances"
                                    : "Allow Players Advance"
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.isGreenRoom
                    ? [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              fab: "",
                                              small: "",
                                              "input-value": _vm.isMuteAll,
                                              "active-class": "grey darken-1",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleMuteAll()
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.isMuteAll
                                                    ? "mic_off "
                                                    : "mic"
                                                )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3313676218
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.isMuteAll ? "Unmute all " : "Mute all"
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }