import Cookies from "js-cookie"
import { computed, watch } from "@vue/composition-api"

import useSurvey from "@/use/useSurvey"
import useStore from "@/use/useStore"

export default function useRoomSurvey() {
  const { store } = useStore()
  const isViewerHostLike = computed(
    () => store.getters["group/isViewerHostLike"]
  )
  const room = computed(() => store.getters.game)
  const user = computed(() => store.getters["auth/user"])
  const gameStatus = computed(() => store.getters["gameStatus"])

  const fields = computed(() => ({
    game_id: room.value.id,
    client_id: room.value.clientID,
    em_id: room.value.seasonID
  }))

  const cookieKey = computed(
    () =>
      `survey-${room.value?.id}-${user.value?.id}-${gameStatus.value?.version}`
  )
  const show = computed(() => user.value?.id && room.value?.showSurvey)
  const onSubmit = () => {
    if (isViewerHostLike.value === false) {
      Cookies.set(cookieKey.value, "true", { expires: 365 })
    }
  }

  const { mount, unmount } = useSurvey(fields, {
    onSubmit,
    autoClose: true,
    buttonText: "Rate your experience",
    buttonColor: "#6E69D1"
  })

  watch(
    show,
    value => {
      if (Cookies.get(cookieKey.value) === "true") return
      if (value) return mount()
      unmount()
    },
    { immediate: true }
  )
}
